import axios from 'axios'
import {
  notificationFailureInterceptor,
  notificationSuccessInterceptor,
} from './interceptors/NotificationInterceptor'

const target =
  process.env.NODE_ENV === 'production'
    ? 'https://neuwagen-internet.de' //https://neuwagen-internet.de //https://portal-live.newcar-online.com //https://b2b-stage.neuwagen-internet.de
    : ''

const subPath =
  process.env.NODE_ENV === 'production' ? '/so-widget' : '/_b2bportal/so-widget'

const apiClient = axios.create({
  // baseURL: 'https://nwo.its-gering.de/_b2bportal/so-widget',
  //baseURL: `${target}/_b2bportal/so-widget`, //${target}/so-widget //${target}/_b2bportal/so-widget
  baseURL: `${target}${subPath}`, //${target}/so-widget //${target}/_b2bportal/so-widget
  // withCredentials: false,
})

apiClient.interceptors.response.use(
  notificationSuccessInterceptor,
  notificationFailureInterceptor
)

export default apiClient
