const notificationSuccessInterceptor = (response) => {
  // const hasNotificationProperty = Object.prototype.hasOwnProperty.call(
  //   response.data,
  //   "notification"
  // )
  // if (!hasNotificationProperty || response.data.notification === true) {
  //   const notification = Object.assign(
  //     {
  //       group: "bottom",
  //       type: "success",
  //       title: "Erfolgreich",
  //     },
  //     response.data.notification
  //   )

  //   if (
  //     requestMethods.includes(response.config.method) &&
  //     !hasNotificationProperty
  //   ) {
  //     switch (response.config.method) {
  //       case "delete":
  //         notification.message = "Datensatz erfolgreich gelöscht"
  //         break
  //       case "patch":
  //         notification.message = "Datensatz erfolgreich aktualisiert"
  //         break
  //       default:
  //         notification.message = "Datensatz erfolgreich gespeichert"
  //     }

  //     methods.notify({
  //       group: notification.group,
  //       type: notification.type,
  //       title: notification.title,
  //       message: notification.message,
  //     })
  //   }
  // }

  return response
}
const notificationFailureInterceptor = (error) => {
  // if (error.response.status === 500) {
  //   methods.notify({
  //     group: "bottom",
  //     type: "error",
  //     title: "Fehler",
  //     message: "Interner Serverfehler",
  //   })
  // }

  // if (error.response.status === 422) {
  //   methods.notify({
  //     group: "bottom",
  //     type: "error",
  //     title: "Fehler",
  //     message: "Validierung fehlgeschlagen",
  //   })
  // }

  // if (error.response.status === 401) {
  //   methods.notify({
  //     group: "bottom",
  //     type: "error",
  //     title: "Fehler",
  //     message: "Authentifizierung fehlgeschlagen",
  //   })
  //   router.push({ name: "login" })
  //   return true
  // }

  return Promise.reject(error)
}

export { notificationSuccessInterceptor, notificationFailureInterceptor }
