import { helpers } from 'vuelidate/lib/validators'
export const mask = 'DD.MM.YYYY'

// import { date } from "quasar";
// export const validOrEmptyDate = (value) => {
//   console.log("validOrEmptyDate", date.formatDate(date.extractDate(value, mask), mask));
//   return (
//     !value || value.length === 0 || value === date.formatDate(date.extractDate(value, mask), mask)
//   );
// };

export const validPLZ = helpers.regex('validPLZ', /^[0-9]{5}$/)
// export const empty = !helpers.req();
export const validOrEmptyPLZ = (value) => {
  const result = !value || value.length === 0 || validPLZ(value)

  return result
}

export const validPhone = helpers.regex(
  'validPhone',
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
)

export const validEmail = (value) => {
  /* eslint-disable */
  const emailRegex =
    /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
  /* eslint-ensable */

  return value.toLowerCase().match(emailRegex) !== null
}

export const complexPass = (value) => {
  return !!(
    !value ||
    (value.match(/[a-z]/) !== null &&
      value.match(/[A-Z]/) !== null &&
      value.match(/[0-9]/) !== null)
  )
}
export const checked = (value) => {
  return value === true
}

export const selectOption = (value) => !!(value && value.trim().length)

/**
 *
 * @param param: Parameter, gegen den validiert wird
 * @param value: Aktueller Wert von $model
 * @returns
 */
export const min = (param) =>
  helpers.withParams({ type: 'min', min: param }, (value) => {
    return !helpers.req(value) || parseFloat(value) >= param
  })

export const validDate = (value) => {
  let valid = typeof value === 'object' && !isNaN(value.getTime())
  // debugger
  //  const date = new Date(this.year, parseInt(this.month) - 1, this.day)
  // const newValue = isNaN( date.getTime() ) ? '' : date

  // console.log("dateValid", date.formatDate(date.extractDate(value, mask), mask));
  // const valid =
  //   (value && value.length) > 0 && value === date.formatDate(date.extractDate(value, mask), mask);
  return valid
}

// export const formatDate = (val) => date.formatDate(val, mask);
// export const today = () => formatDate(new Date());
