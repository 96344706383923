<template>
  <div :class="['its-ak-v-text', flex ? 'its-ak-flex its-ak-flex-col' : '']">
    <label
      v-if="label && !roundedLabel"
      :for="_id"
      :class="[
        'its-ak-block its-ak-text-sm its-ak-font-medium its-ak-text-gray-700',
      ]"
      v-html="label"
    />
    <div :class="[outerClasses, 'its-ak-mt-1 its-ak-relative']">
      <span
        v-if="roundedLabel && label"
        :class="[
          'its-ak-inline-flex its-ak-items-center its-ak-its-ak-px-3 its-ak-rounded-l-md its-ak-border its-ak-border-r-0 its-ak-border-gray-300 its-ak-bg-gray-50 its-ak-text-gray-500',
          stackedLabel
            ? 'its-ak-flex-col its-ak-sm:text-xs'
            : 'its-ak-sm:text-sm',
        ]"
        v-html="label"
      />
      <textarea
        v-if="type === 'textarea'"
        :id="_id"
        @input="
          (e) => {
            $emit('setModel', e.currentTarget.value)
            $emit('input', e.currentTarget.value)
          }
        "
        :value="vref.$model"
        ref="vInput"
        :type="type"
        @blur="inputBlur"
        @keyup="inputKeyup"
        v-bind="$attrs"
        :tabindex="tabindex"
        :autofocus="autofocus"
        :class="getClass"
        :readonly="readonly"
      />
      <input
        v-else
        :id="_id"
        @input="
          (e) => {
            $emit('setModel', e.currentTarget.value)
            $emit('input', e.currentTarget.value)
          }
        "
        :value="vref.$model"
        ref="vInput"
        :type="type"
        @blur="inputBlur"
        @keyup="inputKeyup"
        v-bind="$attrs"
        :tabindex="tabindex"
        :autofocus="autofocus"
        :class="getClass"
        :readonly="readonly"
      />
      <div
        v-show="getErrorMsgs()"
        :style="errorStyleObject"
        class="
          its-ak-absolute
          its-ak-inset-y-0
          its-ak-right-0
          its-ak-pr-3
          its-ak-flex
          its-ak-items-center
          its-ak-pointer-events-none
        "
      >
        <Icon name="validationError" />
      </div>
    </div>
    <div
      class="its-ak-h-5 its-ak-text-red-500 its-ak-ml-2"
      v-html="getErrorMsgs()"
    />
  </div>
</template>

<script>
import vuelidateMixin from '@vuelidate/mixins/vuelidateMixin.js'
import Icon from '@helper/Icon'

export default {
  name: 'VText',
  mixins: [vuelidateMixin],
  components: {
    Icon,
  },
  computed: {
    errorStyleObject() {
      return this.roundedLabel && this.type === 'number'
        ? { right: '30px' }
        : {}
    },
    getClass() {
      // debugger
      let aAddClass = []
      this.getErrorMsgs() && aAddClass.push('its-ak-border-red-300')
      this.readonly &&
        aAddClass.push('its-ak-bg-gray-200 its-ak-cursor-not-allowed')

      return (this.classes + ' ' + aAddClass.join(' ')).trim()
    },
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    outerClasses: {
      type: String,
    },
    classes: {
      type: String,
      default:
        'its-ak-shadow-sm its-ak-focus:ring-indigo-500 its-ak-focus:border-indigo-500 its-ak-block its-ak-w-full its-ak-sm:text-sm its-ak-border-gray-300 its-ak-rounded-md',
    },
    roundedLabel: {
      type: Boolean,
      default: false,
    },
    stackedLabel: {
      type: Boolean,
      default: false,
    },
    flex: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clearValue() {
      this.$emit('setModel', '')
    },
  },
}
</script>
