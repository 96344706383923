<template>
  <div class="wrapper_back_weiter its-ak-flex its-ak-mt-6">
    <button
      class="button_ak button_ak_small button_ak_light button-back its-ak-mr-4"
      v-if="step > 1"
      @click="$emit('back')"
      v-html="$t('zurueck')"
    />
    <button
      :class="{
        'button_ak button_ak_small button-next': true,
        'button_ak_light button_ak_small disabled': submitDisabled,
      }"
      @click="$emit('next')"
      v-html="$t('weiter')"
    />
  </div>
</template>

<script>
export default {
  name: 'FormButtons',
  props: {
    labelBack: {
      type: String,
      default: '&lt; Zurück',
    },
    labelNext: {
      type: String,
      default: 'Weiter &gt;',
    },
    step: {
      type: Number,
      default: 1,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
