<template>
  <div>
    <div class="its-ak-mt-6">
      <!-- <VText :vref="$store.state.filter.detail.so_number" readonly label="Angebotsnummer *" /> -->
      <label
        class="
          its-ak-block its-ak-text-sm its-ak-font-medium its-ak-text-gray-700
        "
        >{{ $t('angebotsnummer') }}</label
      >
      <div class="its-ak-mb-2" v-text="$store.state.filter.detail.so_number" />
      <VText
        v-if="$store.state.filter.detail.so_file_pdf2"
        :vref="$v.formData.wagennummer"
        :label="$t('wagennummer')"
      />

      <VSelect
        :vref="$v.formData.address_title"
        :label="$t('anrede')"
        :options="anredeOptions"
      />

      <VText :vref="$v.formData.address_surname" :label="$t('vorname')" />
      <VText :vref="$v.formData.address_name" :label="$t('name')" />
      <VText :vref="$v.formData.address_firm" :label="$t('firma')" />
    </div>

    <FormButtons @next="submit()" :submitDisabled="submitDisabled" />
  </div>
</template>

<script>
import VText from '@vuelidate/VText.vue'
import VSelect from '@vuelidate/VSelect.vue'
import FormButtons from '@components/FormButtons.vue'
import vuelidatePublicMixin from '@vuelidate/mixins/vuelidatePublicMixin.js'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

const defaultTextValidation = {
  required,
  minLength: minLength(2),
  maxLength: maxLength(100),
}

export default {
  name: 'DetailForm1',
  mixins: [vuelidatePublicMixin],
  components: {
    VText,
    VSelect,
    FormButtons,
  },
  computed: {
    anredeOptions() {
      return [
        this.$t('herr'),
        this.$t('frau'),
        this.$t('anrede-firma'),
        this.$t('divers'),
        this.$t('keine-angabe'),
      ].map((el) => ({
        label: el,
        value: el,
      }))
    },
    formData: {
      get: function () {
        return this.$store.state.formData.formData
      },
      set: function (formData) {
        this.$store.commit('formData/setFormData', formData)
      },
    },
  },
  methods: {
    submit() {
      if (!this.validateAll()) {
        return
      }
      this.$store.commit('formData/setFormData', this.formData)
      this.$store.commit('formData/setStep', 2)
    },
  },
  validations() {
    let validations = {
      formData: {
        address_title: defaultTextValidation,
        offer_id: defaultTextValidation,
        so_number: defaultTextValidation,
        address_surname: defaultTextValidation,
        address_name: defaultTextValidation,
        address_firm: defaultTextValidation,
      },
    }

    if (this.$store.state.filter.detail.so_file_pdf2) {
      validations.formData.wagennummer = defaultTextValidation
    }

    return validations
  },
}
</script>
