var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['its-ak-v-text', _vm.flex ? 'its-ak-flex its-ak-flex-col' : '']},[(_vm.label && !_vm.roundedLabel)?_c('label',{class:[
      'its-ak-block its-ak-text-sm its-ak-font-medium its-ak-text-gray-700' ],attrs:{"for":_vm._id},domProps:{"innerHTML":_vm._s(_vm.label)}}):_vm._e(),_c('div',{class:[_vm.outerClasses, 'its-ak-mt-1 its-ak-relative']},[(_vm.roundedLabel && _vm.label)?_c('span',{class:[
        'its-ak-inline-flex its-ak-items-center its-ak-its-ak-px-3 its-ak-rounded-l-md its-ak-border its-ak-border-r-0 its-ak-border-gray-300 its-ak-bg-gray-50 its-ak-text-gray-500',
        _vm.stackedLabel
          ? 'its-ak-flex-col its-ak-sm:text-xs'
          : 'its-ak-sm:text-sm' ],domProps:{"innerHTML":_vm._s(_vm.label)}}):_vm._e(),(_vm.type === 'textarea')?_c('textarea',_vm._b({ref:"vInput",class:_vm.getClass,attrs:{"id":_vm._id,"type":_vm.type,"tabindex":_vm.tabindex,"autofocus":_vm.autofocus,"readonly":_vm.readonly},domProps:{"value":_vm.vref.$model},on:{"input":function (e) {
          _vm.$emit('setModel', e.currentTarget.value)
          _vm.$emit('input', e.currentTarget.value)
        },"blur":_vm.inputBlur,"keyup":_vm.inputKeyup}},'textarea',_vm.$attrs,false)):_c('input',_vm._b({ref:"vInput",class:_vm.getClass,attrs:{"id":_vm._id,"type":_vm.type,"tabindex":_vm.tabindex,"autofocus":_vm.autofocus,"readonly":_vm.readonly},domProps:{"value":_vm.vref.$model},on:{"input":function (e) {
          _vm.$emit('setModel', e.currentTarget.value)
          _vm.$emit('input', e.currentTarget.value)
        },"blur":_vm.inputBlur,"keyup":_vm.inputKeyup}},'input',_vm.$attrs,false)),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getErrorMsgs()),expression:"getErrorMsgs()"}],staticClass:"\n        its-ak-absolute\n        its-ak-inset-y-0\n        its-ak-right-0\n        its-ak-pr-3\n        its-ak-flex\n        its-ak-items-center\n        its-ak-pointer-events-none\n      ",style:(_vm.errorStyleObject)},[_c('Icon',{attrs:{"name":"validationError"}})],1)]),_c('div',{staticClass:"its-ak-h-5 its-ak-text-red-500 its-ak-ml-2",domProps:{"innerHTML":_vm._s(_vm.getErrorMsgs())}})])}
var staticRenderFns = []

export { render, staticRenderFns }