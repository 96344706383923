<template>
  <div class="wrapper_ak" id="wrapper-so-widget">
    <div class="aktionsangebote">
      <component :is="getCurrentComp" />
    </div>
  </div>
</template>

<style lang="scss">
$primary: #00698c;
$secondary: #c1c100;
$txt-clr1: #696969;
$txt-clr2: #539ab1;
$txt-clr3: #e44c4c;
$txt-clr4: #fff;
$txt-clr5: rgba(0, 0, 0, 0.87);
$txt-clr6: #a6ff4d;
$txt-clr7: rgba(255, 255, 255, 0.7);
$txt1: 15px;
$txt2: 17px;
$txt3: 20px;
$txt4: 22px;
$txt4a: 24px;
$txt4b: 27px;
$txt5: 34px;
$txt6: 40px;
$txt6a: 44px;
$txt7: 60px;
$bg1: #f4f5f7;
$bg2: #1a7999;
$bg3: #01698c;
$bg4: #fff;
$bg5: #147595;
$bg6: #ebf3f6;

.tooltip-inner {
  font-size: 14px !important;
  max-width: 300px;
}

.wrapper_ak {
  margin: 0 auto;
  max-width: 1200px;
  padding: 15px;
  font-family: Roboto;
  font-size: $txt1;
  line-height: 1.4;

  & select,
  select option {
    font-family: Roboto;
    font-size: $txt1;
  }

  & input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  select,
  textarea {
    border-color: rgba(212, 212, 216, 1);
    border-radius: 0.375rem;
    color: inherit;
    line-height: 24px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
  }

  & input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  select {
    height: 42px;
  }

  & input[type='checkbox'],
  input[type='radio'] {
    opacity: unset;
    z-index: 1;
    float: none;
    margin-right: 10px;
    border-color: rgba(212, 212, 216, 1);
    border-radius: 0.25rem;
  }

  & .v-checkbox label {
    font-family: Roboto;
    color: #696969;
    font-weight: 300;
    line-height: 1.6;
    font-size: 15px;
    margin: 0;
  }

  & button.its-ak-mr-4 {
    margin-right: 1rem;
  }

  & .bg1 {
    background: $bg1 !important;
  }

  & .bg2 {
    background: $bg2 !important;
  }

  & .bg3 {
    background: $bg3 !important;
  }

  & .bg4 {
    background: $secondary !important;
  }

  & .bg_bl_grd {
    background: transparent
      linear-gradient(
        90deg,
        #141414 0%,
        #232728 17%,
        #4a5255 50%,
        #1e2021 73%,
        #1d1d1b 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 1;
  }

  & .bg_sec_grd {
    background: #c1c100;
    background: transparent
      linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.01) 0.1%,
        rgba(193, 193, 0, 1) 50%,
        rgba(0, 0, 0, 0.01) 99.9%
      )
      0% 0% no-repeat padding-box;
    opacity: 1;
  }

  & .rounded {
    border-radius: 8px;
  }

  & .glow_sec {
    text-shadow: 0px 0px 15px $secondary;
  }

  & .glow_sec_box {
    box-shadow: 0px 0px 15px $secondary;
  }

  & .txt_upper {
    text-transform: uppercase;
  }

  & .txtsize1 {
    font-size: $txt1;
  }

  & .txtsize2 {
    font-size: $txt2;
  }

  & .txtsize3 {
    font-size: $txt3;
  }
  & .txtsize4 {
    font-size: $txt4;
  }
  & .txtsize4a {
    font-size: $txt4a;
  }
  & .txtsize4b {
    font-size: $txt4b;
  }
  & .txtsize5 {
    font-size: $txt5;
  }
  & .txtsize6 {
    font-size: $txt6;
  }
  & .txtsize6a {
    font-size: $txt6a;
  }
  & .txtsize7 {
    font-size: $txt7;
  }

  & .txtclr_prim {
    color: $primary;
  }

  & .txtclr_sec {
    color: $secondary;
  }

  & .txtclr1 {
    color: $txt-clr1;
  }
  & .txtclr2 {
    color: $txt-clr2;
  }
  & .txtclr3 {
    color: $txt-clr3;
  }
  & .txtclr4 {
    color: $txt-clr4;
  }
  & .txtclr5 {
    color: $txt-clr5;
  }
  & .txtclr6 {
    color: $txt-clr6;
  }
  & .txtclr7 {
    color: $txt-clr7;
  }

  & strong {
    font-weight: 400;
  }

  & p {
    margin-bottom: 20px;
  }

  & a:hover {
    color: $primary;
  }

  & .button_ak {
    background: #00698c;
    color: rgba(255, 255, 255, 0.7);
    border: 1px solid transparent;
    box-shadow: none;
    padding: 0 30px;
    line-height: 53px;
    font-size: 15px;
    border-radius: 500px;
    transition: 0.1s ease-in-out;
    transition-property: all;
    transition-property: color, background-color, background-position,
      border-color, box-shadow;
    font-family: Roboto;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    height: 53px;
  }

  & .button_ak:hover {
    background: #4c96ae;
    color: rgba(255, 255, 255, 1);
    border: 1px solid transparent;
    box-shadow: none;
    display: inline-block;
  }

  & .button_ak_sec {
    background: $secondary;
    color: rgba(255, 255, 255, 0.7);
    border: 1px solid transparent;
    box-shadow: none;
    padding: 0 30px;
    line-height: 53px;
    font-size: 15px;
    border-radius: 500px;
    transition: 0.1s ease-in-out;
    transition-property: all;
    transition-property: color, background-color, background-position,
      border-color, box-shadow;
    font-family: Roboto;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    height: 53px;
  }

  & .button_ak_sec:hover {
    background: #d9d902;
    color: rgba(255, 255, 255, 1);
    border: 1px solid transparent;
    box-shadow: none;
    display: inline-block;
  }

  & .button_ak.fit {
    width: 100%;
  }

  & .button_ak_small {
    padding: 0 20px;
    line-height: 35px;
    font-size: 13px;
    color: $txt-clr4;
    height: 37px;

    & .icon_ak {
      margin-right: 10px;
      position: relative;
      top: -1px;
    }
  }

  & .button_ak_light {
    background: #fff;
    color: $primary;
    border: 1px solid $primary;
  }

  & .button_ak_light:hover {
    background: #fff;
    color: rgba(46, 126, 237, 0.7);
    border-color: rgba(46, 126, 237, 0.7);
  }

  & .button_ak_more,
  .button_ak_more:hover {
    border-radius: 0;
    line-height: 25px;
    font-size: 13px;
    background: $bg6;
    color: $primary;
    border: none;
    position: relative;
    height: auto;
    padding: 10px 15px;
    text-align: left;
  }

  & .button_ak_more:hover {
    color: $txt-clr1;
    background: #e1e8ea;
  }

  & .filter_wrapper {
    text-align: center;
    margin-bottom: 5px;
  }

  & .filter_wrapper:last-child {
    border-right: none;
    padding-right: 0;
  }

  & .list_filter_button {
    border-radius: 0px;
    height: 32px;
    line-height: 32px;
    padding: 0 35px;
    background: $secondary;
    color: $txt-clr4;
    border: none;
    border-radius: 20px;
    text-transform: none;
  }

  & .list_filter_button.active,
  .list_filter_button:hover {
    border-radius: 0px;
    height: 32px;
    line-height: 32px;
    background: $primary;
    color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 20px;
  }

  & .filter_help_icon {
    background: rgba(255, 255, 255, 1);
    color: #4c96ae;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    display: inline-block;
  }

  & .filter_help_icon_wrapper {
    position: relative;
    display: inline-block;
    margin-left: 8px;
  }

  & .filter_help_icon_wrapper:hover .filter_help {
    display: block;
  }

  & .filter_help {
    display: none;
    position: absolute;
    width: 150px;
    top: 25px;
    left: -100px;
    background: #ccc;
    color: $txt-clr5;
    font-size: 12px;
    padding: 5px 10px;
    z-index: 1;
  }

  & .exp_text {
    background: $bg6;
    padding: 10px 15px;
  }

  & .icon_ak {
    margin: 0;
    border: none;
    border-radius: 0;
    overflow: visible;
    font: inherit;
    line-height: inherit;
    color: inherit;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    display: inline-block;
    fill: currentcolor;
    line-height: 0;
    vertical-align: middle;
  }

  & .icon_ak [stroke*='#'] {
    stroke: currentcolor;
  }

  & .button_ak_more .icon_ak {
    position: absolute;
    right: 5px;
    top: 12px;
  }

  & .wrapper_back_button {
    margin: 30px 0;
    text-align: center;
  }

  & .chevron::before {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: '';
    display: inline-block;
    height: 0.65em;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.65em;
  }

  & .chevron.right:before {
    left: 0;
    transform: rotate(45deg);
  }

  & .chevron.bottom:before {
    top: 0;
    transform: rotate(135deg);
  }

  & .chevron.left:before {
    left: 0.25em;
    transform: rotate(-135deg);
  }

  & .flex-flow {
    flex-flow: row wrap;
  }

  & .box-center {
    margin: 0 auto;
    display: block;
  }

  & .marken,
  .modelle {
    background: $bg3;
  }

  & .wrapper_make {
    padding: 20px;
    transition: background-color 0.1s linear;
    cursor: pointer;
    -webkit-box-shadow: 1px 3px 10px 3px #000000;
    box-shadow: 1px 3px 10px 3px #000000;

    & .button_ak {
      font-size: 15px;
      line-height: 40px;
      height: 40px;
      color: $txt-clr4;
      padding-left: 22px;
      display: inline-block;
    }

    & .button_ak .chevron.right:before {
      left: 7px;
      top: 14px;
    }

    & .make_img {
      background-color: $bg4;
      border-radius: 8px;
      display: inline-block;
    }

    & .make {
      font-size: $txt6;
      line-height: 1.4;
      font-weight: 500;
      color: $txt-clr4;
      display: block;
      text-align: center;
    }

    & .make_sub {
      font-size: $txt4a;
      line-height: 1.6;
      font-weight: 400;
      color: $txt-clr4;
      background: $secondary;
      display: inline-block;
      text-align: center;
      text-transform: uppercase;
      padding: 0 20px;
    }

    & .txt1,
    .price {
      color: $txt-clr4;
    }

    & .txt1 {
      font-size: $txt3;
      line-height: 1.5;
      font-weight: 300;
    }

    & .wrapper_price {
      border-right: none;
      border-left: none;
    }

    & .price {
      font-size: $txt6a;
      line-height: 1.1;
      font-weight: 500;
    }
  }

  & .wrapper_make:nth-child(even) {
    background: transparent
      linear-gradient(
        to left,
        #141414 0%,
        #232728 17%,
        #4a5255 34%,
        #1e2021 73%,
        #1d1d1b 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 1;
    &:hover {
      // background: $bg5 !important;
    }
  }

  & .wrapper_make:nth-child(odd) {
    background: transparent
      linear-gradient(
        to right,
        #141414 0%,
        #232728 17%,
        #4a5255 34%,
        #1e2021 73%,
        #1d1d1b 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 1;
    &:hover {
      // background: $bg5 !important;
    }
  }

  & .modelle {
    padding: 40px 0;
    background: transparent
      linear-gradient(
        180deg,
        #141414 0%,
        #232728 17%,
        #4a5255 34%,
        #1e2021 73%,
        #1d1d1b 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 1;

    & .model_headline {
      text-align: center;
      font-size: 52px;
      line-height: 1.2;
      color: #fff;
      font-weight: 100;

      & .icon_ak {
        margin-bottom: 30px;
      }
    }
  }

  & .wrapper_grid {
    padding: 10px;
  }

  & .list_banner {
    padding: 0 20px 10px 20px;
  }

  & .list_filter {
    padding: 20px;
    column-gap: 1.4rem;
  }

  & .wrapper_model {
    padding: 0 10px;
    margin-bottom: 20px;

    & .button_ak {
      font-size: 15px;
      line-height: 40px;
      height: 40px;
      color: $txt-clr4;
      padding-left: 22px;
      display: inline-block;
    }

    & .button_ak .chevron.right:before {
      left: 7px;
      top: 14px;
    }

    & .model_headline {
      text-align: center;
      font-size: $txt7;
      line-height: 1.1;
      color: $txt-clr4;
      font-weight: 100;
    }

    & .wrapper_model_inner {
      padding: 15px;
      background-color: $bg4;
      text-align: center;
      position: relative;
      padding-bottom: 100px;
      cursor: pointer;
      height: 100%;

      & .model_image {
        transition: all 0.2s ease-in-out;
      }

      &:hover .model_image {
        transform: scale(1.03, 1.03);
      }

      & .wrapper_button {
        position: absolute;
        bottom: 30px;
        width: 100%;
      }

      & .wrapper_model_image {
        width: 100%;
        overflow: hidden;
        margin-bottom: 20px;
      }

      & .make_model1 {
        font-size: $txt4;
        color: $primary;
        font-weight: 500;
      }

      & hr {
        border: 0;
        border-bottom: 2px solid #ece8e8;
        display: block;
        line-height: 0;
        margin: 15px 0;
      }

      & .price {
        font-size: $txt6;
        color: $secondary;
        font-weight: 500;
      }

      & .price {
        font-size: $txt6;
        color: $secondary;
      }

      & .text_panel p {
        font-size: $txt2;
        line-height: 1.5;
        color: $txt-clr5;
      }
    }
  }

  & .detail {
    font-family: Roboto;
    color: $txt-clr1;
    font-weight: 300;
    line-height: 1.6;
    font-size: 15px;

    & p {
      margin-bottom: 20px;
    }

    & .wrapper_images {
      height: 300px;
      overflow: hidden;
    }

    & .VueCarousel-wrapper {
      border-radius: 8px;
    }

    & .wrapper_ad_image {
      margin: 20px 0;
    }

    & .detail_headline {
      line-height: 1.1;
      font-weight: bold;
      font-size: 30px;
      color: #fff;
    }

    & .offer_top,
    .offer_main {
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid #e9e9e9;
    }

    & .number {
      margin-bottom: 40px;
      margin-top: 4px;
    }

    & .wrapper_price {
      padding: 20px;
      margin-top: 30px;
    }

    & .price,
    .note1,
    .wrapper_discount {
      line-height: 1.1;
      font-weight: 500;
    }

    & .note1,
    .wrapper_discount,
    .wrapper_amount,
    .wrapper_button {
      padding-bottom: 25px;
      padding-top: 25px;
      border-bottom: 1px solid #666565;
    }

    & .wrapper_discount .discount {
      line-height: 60px;
      margin-bottom: 20px;
    }

    & .wrapper_amount {
      line-height: 40px;
    }

    & .wrapper_button .button_ak,
    .wrapper_button .button_ak_sec {
      margin-top: 20px;
    }

    .wrapper_button {
      border-bottom: none;
    }

    & .model_headline {
      font-weight: bold;
      margin-bottom: 10px;
    }

    & .offer_main1 {
      padding: 1rem 1rem;
    }

    & .offer_main2 {
      padding: 0px 10px;
      border-left: 1px solid $bg1;
      padding-right: 0;

      & hr {
        margin: 20px 0;
      }
    }

    & .wrapper_detail_thumb {
      cursor: zoom-in;
    }

    & .detail_thumb {
      width: 100%;
    }

    & .text_info_img {
      position: relative;
      top: 7px;
      color: rgba(255, 255, 255, 0.7);
    }

    & .wrapper_model_desc {
      & ul {
        list-style: disc;
        margin: 0 0 10px 25px !important;
      }
    }

    & .pdf_box {
      padding: 20px 30px 20px 70px;
      color: $txt-clr7;
      cursor: pointer;
      position: relative;
      margin-bottom: 20px;

      & .pdf_box_icon {
        position: absolute;
        color: #fff;
        left: 20px;
      }

      & .pdf_box_title {
        color: $txt-clr4;
        font-size: $txt4;
      }
    }

    & .pdf_box:hover {
      background: $bg5;

      & .pdf_box_title {
        color: $secondary;
      }
    }

    & .wrapper_model_specs {
      padding-bottom: 20px;
      margin-bottom: 40px;
      border-bottom: 1px solid #e9e9e9;
    }

    & .model_specs_headline {
      font-weight: 400;
      border-bottom: 1px solid $primary;
      margin-bottom: 10px;
    }

    & .specs_head {
      font-size: $txt4;
      margin-bottom: 15px;
    }

    & .wrapper_cons_text {
      margin-top: 20px;
      font-size: $txt1;
    }

    & .anfrage_head {
      margin-bottom: 40px;

      & .head1 {
        text-align: center;
      }

      & .head2 {
        font-size: 30px;
        padding: 30px;
      }
    }

    & .anfrage_desc {
      padding: 20px 35px;

      & .anfrage_desc_head {
        font-size: 22px;
        margin-bottom: 20px;
      }
    }

    & .wrapper_form section svg {
      width: 30px;
      height: 30px;
    }

    & .v-checkbox .label {
      position: relative;
      top: -5px;
      margin: 0 0 5px 0;
      padding: 0;
      background: none;
      text-transform: none;
    }

    & .leasing_condition {
      padding: 0;
      background-color: $primary;
      margin-bottom: 10px;
      & .button_ak_more {
        text-align: center;
        &.open {
          background: $primary;
          color: #fff;
        }
        &.close {
          background: $bg1;
          color: $primary;
        }
      }
    }

    & .leasing_var_head {
      font-size: 22px;
      background-color: $secondary;
      border-bottom: 2px solid #000;
      color: #fff;
      font-weight: bold;
      text-align: center;
      padding: 10px 5px;
    }

    & .leasing_info {
      background-color: $bg1;
      padding: 20px 60px;
      & p {
        margin-bottom: 10px;
      }
    }

    & .leasing_table {
      overflow: auto;
      padding: 20px 10px;
      background-color: $primary;
      border-bottom: 2px solid #000;

      & table {
        border: none;
        border-collapse: collapse;
        margin: 0 auto;
      }

      & th {
        font-size: $txt1;
        font-weight: bold;
        color: #fff;
      }

      & td {
        font-size: $txt1;
        font-weight: bold;
        color: #fff;
      }

      & th,
      td {
        text-align: center;
        padding: 5px 15px;
        border-left: 1px solid $secondary;
        border-bottom: 1px solid $secondary;
      }

      & td:first-child,
      th:first-child {
        border-left: none;
      }

      & tr:last-child td {
        border-bottom: none;
      }
    }
  }
}
@media (min-width: 640px) {
  .wrapper_ak .filter_wrapper {
    border-right: 1px solid #fff;
    padding-right: 10px;
    margin-bottom: 0;
  }

  .wrapper_ak .filter_help {
    top: 30px;
    left: 0;
  }
}

.wrapper_ak .its-col-xs-12 {
  width: 100%;
}
.wrapper_ak .its-col-xs-11 {
  width: 91.66666667%;
}
.wrapper_ak .its-col-xs-10 {
  width: 83.33333333%;
}
.wrapper_ak .its-col-xs-9 {
  width: 75%;
}
.wrapper_ak .its-col-xs-8 {
  width: 66.66666667%;
}
.wrapper_ak .its-col-xs-7 {
  width: 58.33333333%;
}
.wrapper_ak .its-col-xs-6 {
  width: 50%;
}
.wrapper_ak .its-col-xs-5 {
  width: 41.66666667%;
}
.wrapper_ak .its-col-xs-4 {
  width: 33.33333333%;
}
.wrapper_ak .its-col-xs-3 {
  width: 25%;
}
.wrapper_ak .its-col-xs-2 {
  width: 16.66666667%;
}
.wrapper_ak .its-col-xs-1 {
  width: 8.33333333%;
}
.wrapper_ak .its-col-xs-pull-12 {
  right: 100%;
}
.wrapper_ak .its-col-xs-pull-11 {
  right: 91.66666667%;
}
.wrapper_ak .its-col-xs-pull-10 {
  right: 83.33333333%;
}
.wrapper_ak .its-col-xs-pull-9 {
  right: 75%;
}
.wrapper_ak .its-col-xs-pull-8 {
  right: 66.66666667%;
}
.wrapper_ak .its-col-xs-pull-7 {
  right: 58.33333333%;
}
.wrapper_ak .its-col-xs-pull-6 {
  right: 50%;
}
.wrapper_ak .its-col-xs-pull-5 {
  right: 41.66666667%;
}
.wrapper_ak .its-col-xs-pull-4 {
  right: 33.33333333%;
}
.wrapper_ak .its-col-xs-pull-3 {
  right: 25%;
}
.wrapper_ak .its-col-xs-pull-2 {
  right: 16.66666667%;
}
.wrapper_ak .its-col-xs-pull-1 {
  right: 8.33333333%;
}
.wrapper_ak .its-col-xs-pull-0 {
  right: auto;
}
.wrapper_ak .its-col-xs-push-12 {
  left: 100%;
}
.wrapper_ak .its-col-xs-push-11 {
  left: 91.66666667%;
}
.wrapper_ak .its-col-xs-push-10 {
  left: 83.33333333%;
}
.wrapper_ak .its-col-xs-push-9 {
  left: 75%;
}
.wrapper_ak .its-col-xs-push-8 {
  left: 66.66666667%;
}
.wrapper_ak .its-col-xs-push-7 {
  left: 58.33333333%;
}
.wrapper_ak .its-col-xs-push-6 {
  left: 50%;
}
.wrapper_ak .its-col-xs-push-5 {
  left: 41.66666667%;
}
.wrapper_ak .its-col-xs-push-4 {
  left: 33.33333333%;
}
.wrapper_ak .its-col-xs-push-3 {
  left: 25%;
}
.wrapper_ak .its-col-xs-push-2 {
  left: 16.66666667%;
}
.wrapper_ak .its-col-xs-push-1 {
  left: 8.33333333%;
}
.wrapper_ak .its-col-xs-push-0 {
  left: auto;
}
.wrapper_ak .its-col-xs-offset-12 {
  margin-left: 100%;
}
.wrapper_ak .its-col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.wrapper_ak .its-col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.wrapper_ak .its-col-xs-offset-9 {
  margin-left: 75%;
}
.wrapper_ak .its-col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.wrapper_ak .its-col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.wrapper_ak .its-col-xs-offset-6 {
  margin-left: 50%;
}
.wrapper_ak .its-col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.wrapper_ak .its-col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.wrapper_ak .its-col-xs-offset-3 {
  margin-left: 25%;
}
.wrapper_ak .its-col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.wrapper_ak .its-col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.wrapper_ak .its-col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .wrapper_ak .its-col-sm-12 {
    width: 100%;
  }
  .wrapper_ak .its-col-sm-11 {
    width: 91.66666667%;
  }
  .wrapper_ak .its-col-sm-10 {
    width: 83.33333333%;
  }
  .wrapper_ak .its-col-sm-9 {
    width: 75%;
  }
  .wrapper_ak .its-col-sm-8 {
    width: 66.66666667%;
  }
  .wrapper_ak .its-col-sm-7 {
    width: 58.33333333%;
  }
  .wrapper_ak .its-col-sm-6 {
    width: 50%;
  }
  .wrapper_ak .its-col-sm-5 {
    width: 41.66666667%;
  }
  .wrapper_ak .its-col-sm-4 {
    width: 33.33333333%;
  }
  .wrapper_ak .its-col-sm-3 {
    width: 25%;
  }
  .wrapper_ak .its-col-sm-2 {
    width: 16.66666667%;
  }
  .wrapper_ak .its-col-sm-1 {
    width: 8.33333333%;
  }
  .wrapper_ak .its-col-sm-pull-12 {
    right: 100%;
  }
  .wrapper_ak .its-col-sm-pull-11 {
    right: 91.66666667%;
  }
  .wrapper_ak .its-col-sm-pull-10 {
    right: 83.33333333%;
  }
  .wrapper_ak .its-col-sm-pull-9 {
    right: 75%;
  }
  .wrapper_ak .its-col-sm-pull-8 {
    right: 66.66666667%;
  }
  .wrapper_ak .its-col-sm-pull-7 {
    right: 58.33333333%;
  }
  .wrapper_ak .its-col-sm-pull-6 {
    right: 50%;
  }
  .wrapper_ak .its-col-sm-pull-5 {
    right: 41.66666667%;
  }
  .wrapper_ak .its-col-sm-pull-4 {
    right: 33.33333333%;
  }
  .wrapper_ak .its-col-sm-pull-3 {
    right: 25%;
  }
  .wrapper_ak .its-col-sm-pull-2 {
    right: 16.66666667%;
  }
  .wrapper_ak .its-col-sm-pull-1 {
    right: 8.33333333%;
  }
  .wrapper_ak .its-col-sm-pull-0 {
    right: auto;
  }
  .wrapper_ak .its-col-sm-push-12 {
    left: 100%;
  }
  .wrapper_ak .its-col-sm-push-11 {
    left: 91.66666667%;
  }
  .wrapper_ak .its-col-sm-push-10 {
    left: 83.33333333%;
  }
  .wrapper_ak .its-col-sm-push-9 {
    left: 75%;
  }
  .wrapper_ak .its-col-sm-push-8 {
    left: 66.66666667%;
  }
  .wrapper_ak .its-col-sm-push-7 {
    left: 58.33333333%;
  }
  .wrapper_ak .its-col-sm-push-6 {
    left: 50%;
  }
  .wrapper_ak .its-col-sm-push-5 {
    left: 41.66666667%;
  }
  .wrapper_ak .its-col-sm-push-4 {
    left: 33.33333333%;
  }
  .wrapper_ak .its-col-sm-push-3 {
    left: 25%;
  }
  .wrapper_ak .its-col-sm-push-2 {
    left: 16.66666667%;
  }
  .wrapper_ak .its-col-sm-push-1 {
    left: 8.33333333%;
  }
  .wrapper_ak .its-col-sm-push-0 {
    left: auto;
  }
  .wrapper_ak .its-col-sm-offset-12 {
    margin-left: 100%;
  }
  .wrapper_ak .its-col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .wrapper_ak .its-col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .wrapper_ak .its-col-sm-offset-9 {
    margin-left: 75%;
  }
  .wrapper_ak .its-col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .wrapper_ak .its-col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .wrapper_ak .its-col-sm-offset-6 {
    margin-left: 50%;
  }
  .wrapper_ak .its-col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .wrapper_ak .its-col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .wrapper_ak .its-col-sm-offset-3 {
    margin-left: 25%;
  }
  .wrapper_ak .its-col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .wrapper_ak .its-col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .wrapper_ak .its-col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .wrapper_ak .its-col-md-12 {
    width: 100%;
  }
  .wrapper_ak .its-col-md-11 {
    width: 91.66666667%;
  }
  .wrapper_ak .its-col-md-10 {
    width: 83.33333333%;
  }
  .wrapper_ak .its-col-md-9 {
    width: 75%;
  }
  .wrapper_ak .its-col-md-8 {
    width: 66.66666667%;
  }
  .wrapper_ak .its-col-md-7 {
    width: 58.33333333%;
  }
  .wrapper_ak .its-col-md-6 {
    width: 50%;
  }
  .wrapper_ak .its-col-md-5 {
    width: 41.66666667%;
  }
  .wrapper_ak .its-col-md-4 {
    width: 33.33333333%;
  }
  .wrapper_ak .its-col-md-3 {
    width: 25%;
  }
  .wrapper_ak .its-col-md-2 {
    width: 16.66666667%;
  }
  .wrapper_ak .its-col-md-1 {
    width: 8.33333333%;
  }
  .wrapper_ak .its-col-md-pull-12 {
    right: 100%;
  }
  .wrapper_ak .its-col-md-pull-11 {
    right: 91.66666667%;
  }
  .wrapper_ak .its-col-md-pull-10 {
    right: 83.33333333%;
  }
  .wrapper_ak .its-col-md-pull-9 {
    right: 75%;
  }
  .wrapper_ak .its-col-md-pull-8 {
    right: 66.66666667%;
  }
  .wrapper_ak .its-col-md-pull-7 {
    right: 58.33333333%;
  }
  .wrapper_ak .its-col-md-pull-6 {
    right: 50%;
  }
  .wrapper_ak .its-col-md-pull-5 {
    right: 41.66666667%;
  }
  .wrapper_ak .its-col-md-pull-4 {
    right: 33.33333333%;
  }
  .wrapper_ak .its-col-md-pull-3 {
    right: 25%;
  }
  .wrapper_ak .its-col-md-pull-2 {
    right: 16.66666667%;
  }
  .wrapper_ak .its-col-md-pull-1 {
    right: 8.33333333%;
  }
  .wrapper_ak .its-col-md-pull-0 {
    right: auto;
  }
  .wrapper_ak .its-col-md-push-12 {
    left: 100%;
  }
  .wrapper_ak .its-col-md-push-11 {
    left: 91.66666667%;
  }
  .wrapper_ak .its-col-md-push-10 {
    left: 83.33333333%;
  }
  .wrapper_ak .its-col-md-push-9 {
    left: 75%;
  }
  .wrapper_ak .its-col-md-push-8 {
    left: 66.66666667%;
  }
  .wrapper_ak .its-col-md-push-7 {
    left: 58.33333333%;
  }
  .wrapper_ak .its-col-md-push-6 {
    left: 50%;
  }
  .wrapper_ak .its-col-md-push-5 {
    left: 41.66666667%;
  }
  .wrapper_ak .its-col-md-push-4 {
    left: 33.33333333%;
  }
  .wrapper_ak .its-col-md-push-3 {
    left: 25%;
  }
  .wrapper_ak .its-col-md-push-2 {
    left: 16.66666667%;
  }
  .wrapper_ak .its-col-md-push-1 {
    left: 8.33333333%;
  }
  .wrapper_ak .its-col-md-push-0 {
    left: auto;
  }
  .wrapper_ak .its-col-md-offset-12 {
    margin-left: 100%;
  }
  .wrapper_ak .its-col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .wrapper_ak .its-col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .wrapper_ak .its-col-md-offset-9 {
    margin-left: 75%;
  }
  .wrapper_ak .its-col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .wrapper_ak .its-col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .wrapper_ak .its-col-md-offset-6 {
    margin-left: 50%;
  }
  .wrapper_ak .its-col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .wrapper_ak .its-col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .wrapper_ak .its-col-md-offset-3 {
    margin-left: 25%;
  }
  .wrapper_ak .its-col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .wrapper_ak .its-col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .wrapper_ak .its-col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .wrapper_ak .wrapper_make .wrapper_price {
    border-right: 1px solid #514f4f;
    border-left: 1px solid #514f4f;
  }
  .wrapper_ak .its-col-lg-12 {
    width: 100%;
  }
  .wrapper_ak .its-col-lg-11 {
    width: 91.66666667%;
  }
  .wrapper_ak .its-col-lg-10 {
    width: 83.33333333%;
  }
  .wrapper_ak .its-col-lg-9 {
    width: 75%;
  }
  .wrapper_ak .its-col-lg-8 {
    width: 66.66666667%;
  }
  .wrapper_ak .its-col-lg-7 {
    width: 58.33333333%;
  }
  .wrapper_ak .its-col-lg-6 {
    width: 50%;
  }
  .wrapper_ak .its-col-lg-5 {
    width: 41.66666667%;
  }
  .wrapper_ak .its-col-lg-4 {
    width: 33.33333333%;
  }
  .wrapper_ak .its-col-lg-3 {
    width: 25%;
  }
  .wrapper_ak .its-col-lg-2 {
    width: 16.66666667%;
  }
  .wrapper_ak .its-col-lg-1 {
    width: 8.33333333%;
  }
  .wrapper_ak .its-col-lg-pull-12 {
    right: 100%;
  }
  .wrapper_ak .its-col-lg-pull-11 {
    right: 91.66666667%;
  }
  .wrapper_ak .its-col-lg-pull-10 {
    right: 83.33333333%;
  }
  .wrapper_ak .its-col-lg-pull-9 {
    right: 75%;
  }
  .wrapper_ak .its-col-lg-pull-8 {
    right: 66.66666667%;
  }
  .wrapper_ak .its-col-lg-pull-7 {
    right: 58.33333333%;
  }
  .wrapper_ak .its-col-lg-pull-6 {
    right: 50%;
  }
  .wrapper_ak .its-col-lg-pull-5 {
    right: 41.66666667%;
  }
  .wrapper_ak .its-col-lg-pull-4 {
    right: 33.33333333%;
  }
  .wrapper_ak .its-col-lg-pull-3 {
    right: 25%;
  }
  .wrapper_ak .its-col-lg-pull-2 {
    right: 16.66666667%;
  }
  .wrapper_ak .its-col-lg-pull-1 {
    right: 8.33333333%;
  }
  .wrapper_ak .its-col-lg-pull-0 {
    right: auto;
  }
  .wrapper_ak .its-col-lg-push-12 {
    left: 100%;
  }
  .wrapper_ak .its-col-lg-push-11 {
    left: 91.66666667%;
  }
  .wrapper_ak .its-col-lg-push-10 {
    left: 83.33333333%;
  }
  .wrapper_ak .its-col-lg-push-9 {
    left: 75%;
  }
  .wrapper_ak .its-col-lg-push-8 {
    left: 66.66666667%;
  }
  .wrapper_ak .its-col-lg-push-7 {
    left: 58.33333333%;
  }
  .wrapper_ak .its-col-lg-push-6 {
    left: 50%;
  }
  .wrapper_ak .its-col-lg-push-5 {
    left: 41.66666667%;
  }
  .wrapper_ak .its-col-lg-push-4 {
    left: 33.33333333%;
  }
  .wrapper_ak .its-col-lg-push-3 {
    left: 25%;
  }
  .wrapper_ak .its-col-lg-push-2 {
    left: 16.66666667%;
  }
  .wrapper_ak .its-col-lg-push-1 {
    left: 8.33333333%;
  }
  .wrapper_ak .its-col-lg-push-0 {
    left: auto;
  }
  .wrapper_ak .its-col-lg-offset-12 {
    margin-left: 100%;
  }
  .wrapper_ak .its-col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .wrapper_ak .its-col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .wrapper_ak .its-col-lg-offset-9 {
    margin-left: 75%;
  }
  .wrapper_ak .its-col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .wrapper_ak .its-col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .wrapper_ak .its-col-lg-offset-6 {
    margin-left: 50%;
  }
  .wrapper_ak .its-col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .wrapper_ak .its-col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .wrapper_ak .its-col-lg-offset-3 {
    margin-left: 25%;
  }
  .wrapper_ak .its-col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .wrapper_ak .its-col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .wrapper_ak .its-col-lg-offset-0 {
    margin-left: 0%;
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import Marken from '@/components/Marken.vue'
import Modelle from '@/components/Modelle.vue'
import Detail from '@/components/Detail.vue'

export default {
  name: 'Aktionsangebote',
  components: {
    Marken,
    Modelle,
    Detail,
  },
  props: {
    orderAgentToken: String,
    channel: String,
  },
  computed: {
    ...mapGetters('env', ['getCurrentComp']),
  },
  methods: {
    ...mapActions('env', ['loadAgentData']),
  },
  mounted() {
    this.loadAgentData()
  },
}
</script>
