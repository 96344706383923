<template>
  <div class="v-select">
    <label
      v-if="label"
      :for="_id"
      v-html="label"
      class="
        its-ak-block its-ak-text-sm its-ak-font-medium its-ak-text-gray-700
      "
    />
    <div class="relative">
      <select
        v-if="options"
        @change="
          (e) => {
            $emit('setModel', e.currentTarget.value)
            $emit('change', e.currentTarget.value)
          }
        "
        :id="_id"
        @blur="inputBlur"
        v-bind="$attrs"
        :disabled="!options.length || readonly"
        :tabindex="tabindex"
        :autofocus="autofocus"
        class="
          select
          its-ak-mt-1
          disabled:its-ak-bg-gray-100
          its-ak-block
          its-ak-w-full
          its-ak-pl-3
          its-ak-pr-10
          its-ak-py-2
          its-ak-text-base
          its-ak-border-gray-300
          focus:its-ak-outline-none
          focus:its-ak-ring-indigo-500
          focus:its-ak-border-indigo-500
          sm:its-ak-text-sm
          its-ak-rounded-md
        "
        v-bind:class="{
          'its-ak-border-red-300': getErrorMsgs(),
          'its-ak-cursor-not-allowed': !options.length,
        }"
      >
        <option
          v-if="placeholder"
          value=""
          class="hidden"
          :selected="!vref.$model"
          v-html="placeholder"
        ></option>

        <option
          v-for="(item, itemIx) in options"
          :key="itemIx"
          :value="item[valueProp]"
          :selected="vref.$model === item[valueProp]"
        >
          {{ item[labelProp] }}
        </option>
      </select>

      <div
        v-show="getErrorMsgs()"
        class="
          its-ak-absolute
          its-ak-inset-y-0
          its-ak-right-5
          its-ak-pr-3
          its-ak-flex
          its-ak-items-center
          its-ak-pointer-events-none
        "
      >
        <Icon name="validationError" />
      </div>
    </div>
    <div
      class="its-ak-h-5 its-ak-text-red-500 its-ak-ml-2"
      v-html="getErrorMsgs()"
    />
  </div>
</template>

<script>
import vuelidateMixin from '@vuelidate/mixins/vuelidateMixin.js'
import Icon from '@helper/Icon'

export default {
  name: 'VSelect',
  components: {
    Icon,
  },
  mixins: [vuelidateMixin],
  props: {
    readonly: Boolean,
    options: Array,
    placeholder: String,
    valueProp: {
      type: String,
      default: 'value',
    },
    labelProp: {
      type: String,
      default: 'label',
    },
    prefix: String,
  },
  methods: {
    clearValue() {
      this.$emit('setModel', '')
    },
  },
}
</script>

<style lang="scss"></style>
