<template>
  <div>
    <div class="its-ak-mt-6">
      <VText
        type="textarea"
        :vref="$v.formData.order_msg_text"
        :label="$t('nachricht')"
      />
    </div>

    <FormButtons
      @next="submit()"
      @back="back()"
      :step="4"
      :submitDisabled="submitDisabled"
    />
  </div>
</template>

<script>
import FormButtons from '@components/FormButtons.vue'
import vuelidatePublicMixin from '@vuelidate/mixins/vuelidatePublicMixin.js'
import VText from './helper/vuelidate/VText.vue'

export default {
  name: 'DetailForm4',
  mixins: [vuelidatePublicMixin],
  components: {
    VText,
    FormButtons,
  },
  computed: {
    formData: {
      get: function () {
        return this.$store.state.formData.formData
      },
      set: function (formData) {
        this.$store.commit('formData/setFormData', formData)
      },
    },
  },
  methods: {
    submit() {
      if (!this.validateAll()) {
        return
      }
      this.$store.commit('formData/setFormData', this.formData)
      this.$store.commit('formData/setStep', 5)
    },
    back() {
      this.$store.commit('formData/setFormData', this.formData)
      this.$store.commit('formData/setStep', 3)
    },
  },
  validations() {
    let validations = {
      formData: {
        order_msg_text: {},
      },
    }

    return validations
  },
}
</script>
