<template>
  <div>
    <div v-if="formData.addBoxes[0]" class="txtclr5 bolder_ak its-ak-mb-2">
      {{ $t('weitere-optionen') }}
    </div>
    <div v-for="(box, ix) in formData.addBoxes" :key="ix">
      <VCheckbox
        v-if="box.label.length"
        :vref="$v.formData.addBoxes[ix].value"
        :label="box.label"
      />
      <div v-else v-html="'&nbsp;'" />
    </div>
  </div>
</template>

<script>
import VCheckbox from '@vuelidate/VCheckbox.vue'
import vuelidatePublicMixin from '@vuelidate/mixins/vuelidatePublicMixin.js'

export default {
  name: 'AddBoxes',
  mixins: [vuelidatePublicMixin],
  components: {
    VCheckbox,
  },
  computed: {
    formData: {
      get: function () {
        return this.$store.state.formData.formData
      },
      set: function (formData) {
        this.$store.commit('formData/setFormData', formData)
      },
    },
  },
  validations() {
    let validations = {
      formData: {
        addBoxes: {},
      },
    }

    Object.keys(this.formData.addBoxes).forEach(
      (key) => (validations.formData.addBoxes[key] = { value: {} })
    )

    return validations
  },
}
</script>
