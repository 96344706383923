const mkFormData = function (formData) {
  const FormData = require('form-data')
  let data = new FormData()

  for (let key in formData) {
    data.append(key, formData[key])
  }

  return data
}

export default mkFormData
