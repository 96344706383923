<template>
  <div>
    <div class="its-ak-mt-6">
      <div class="its-ak-nachricht">
        <p>{{ $t('anfrage-erhalten-titel') }}</p>
        <p>
          {{ $t('anfrage-erhalten-text') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailForm6',
  methods: {},
}
</script>
