<template>
  <div>
    <div class="its-ak-mt-6">
      <VCheckbox :vref="$v.formData.einwilligung" :label="$t('datenschutz')" />
      <p>
        {{ $t('ich-stimme') }}
        <a
          class="txtclr_prim"
          target="_blank"
          :href="getAgentData.commissionPrivacyLink"
          >{{ $t('datenschutzbedingungen') }}</a
        >
        {{ $t('willige-ein') }}
      </p>
      <vue-recaptcha
        :sitekey="getSitekey"
        :loadRecaptchaScript="true"
        ref="recaptcha"
      ></vue-recaptcha>

      <!-- <VText :vref="$v.formData.captcha" label="Sicherheitscode *" /> -->
    </div>

    <FormButtons
      @next="submit()"
      @back="back()"
      :step="5"
      :labelNext="$t('senden')"
      :submitDisabled="submitDisabled || !reCaptchaValid"
    />
  </div>
</template>

<script>
// import VText from '@vuelidate/VText.vue'
import { mapGetters } from 'vuex'
import VCheckbox from '@vuelidate/VCheckbox.vue'
import FormButtons from '@components/FormButtons.vue'

import vuelidatePublicMixin from '@vuelidate/mixins/vuelidatePublicMixin.js'
import { checked } from '@vuelidate/validators'

import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'DetailForm5',
  mixins: [vuelidatePublicMixin],
  components: {
    // VText,
    VCheckbox,
    FormButtons,
    VueRecaptcha,
  },
  computed: {
    ...mapGetters('env', ['getAgentData', 'getSitekey']),
    formData: {
      get: function () {
        return this.$store.state.formData.formData
      },
      set: function (formData) {
        this.$store.commit('formData/setFormData', formData)
      },
    },
  },
  data() {
    return {
      termsMessage: '',
      reCaptchaValid: false,
      reCaptchaResponse: null,
    }
  },
  methods: {
    submit() {
      if (!this.validateAll()) {
        return
      }
      document.querySelector('.button-next').style.display = 'none'
      this.formData.reCaptchaResponse = this.reCaptchaResponse
      this.$store.commit('formData/setFormData', this.formData)

      this.$store.state.env.isLocal && console.log('Send Data,', this.formData)
      this.$store.dispatch('formData/send')
    },
    back() {
      this.$store.commit('formData/setFormData', this.formData)
      this.$store.commit('formData/setStep', 3)
    },
  },
  mounted: function () {
    const scope = this
    this.$refs.recaptcha.$on('verify', function (response) {
      scope.reCaptchaValid = true
      scope.reCaptchaResponse = response
    })
  },
  validations() {
    let validations = {
      formData: {
        einwilligung: { checked },
        // captcha: {},
      },
    }

    return validations
  },
}
</script>
