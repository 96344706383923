<template>
  <div>
    <div class="its-ak-mt-6">
      <!--<VSelect
        :vref="$v.formData.kaufplanung"
        label="Kauf-Planung"
        :options="kaufplanungOptions"
      />-->
      <VText :vref="$v.formData.address_postal" :label="$t('plz')" />
      <VText :vref="$v.formData.address_city" :label="$t('ort')" />
      <VText :vref="$v.formData.address_street" :label="$t('strasse')" />
      <VText :vref="$v.formData.address_tel1" :label="$t('telefon')" />
      <VText :vref="$v.formData.address_email" :label="$t('email')" />
    </div>

    <FormButtons
      @next="submit()"
      @back="back()"
      :step="2"
      :submitDisabled="submitDisabled"
    />
  </div>
</template>

<script>
import VText from '@vuelidate/VText.vue'
//import VSelect from '@vuelidate/VSelect.vue'
import FormButtons from '@components/FormButtons.vue'
import vuelidatePublicMixin from '@vuelidate/mixins/vuelidatePublicMixin.js'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validPLZ, validEmail } from '@vuelidate/validators.js'

const defaultTextValidation = {
  required,
  minLength: minLength(2),
  maxLength: maxLength(100),
}

export default {
  name: 'DetailForm2',
  mixins: [vuelidatePublicMixin],
  components: {
    VText,
    //VSelect,
    FormButtons,
  },
  computed: {
    kaufplanungOptions() {
      const options = this.$store.state.filter.detail.so_request_options1
        .trim()
        .split('\n')
        .map((el) => ({ label: el, value: el }))

      if (!this.$store.state.formData.formData.kaufplanung) {
        this.$store.commit('formData/initKaufplanung', options[0].value)
      }

      return options
    },
    formData: {
      get: function () {
        return this.$store.state.formData.formData
      },
      set: function (formData) {
        this.$store.commit('formData/setFormData', formData)
      },
    },
  },
  methods: {
    submit() {
      if (!this.validateAll()) {
        return
      }
      this.$store.commit('formData/setFormData', this.formData)
      this.$store.commit('formData/setStep', 3)
    },
    back() {
      this.$store.commit('formData/setFormData', this.formData)
      this.$store.commit('formData/setStep', 1)
    },
  },
  validations() {
    let validations = {
      formData: {
        //kaufplanung: { required },
        address_street: defaultTextValidation,
        address_city: defaultTextValidation,
        address_postal: { required, validPLZ },
        address_tel1: defaultTextValidation,
        address_email: { required, validEmail },
      },
    }

    return validations
  },
}
</script>
