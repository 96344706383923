<template>
  <div v-if="getModelle && $store.state.env.langLoaded" class="modelle">
    <!--
    <div class="model_headline">
      <span class="icon_ak">
        <svg
          width="100"
          height="100"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.74,20 L7.73,12 L3,12 L15.43,1 L12.32,9 L17.02,9 L4.74,20 L4.74,20 L4.74,20 Z M9.18,11 L7.1,16.39 L14.47,10 L10.86,10 L12.99,4.67 L5.61,11 L9.18,11 L9.18,11 L9.18,11 Z"
          ></path>
        </svg>
      </span>
      <br />
      <span v-html="getModelle.headline" />
      <br />
      {{ $t('blitz-angebote') }}: <span v-html="getCurrentMarkeName" />
    </div>
    -->
    <div v-if="!makeMode" class="wrapper_back_button">
      <button class="button_ak button_ak_light" @click="back">
        {{ $t('zurueck-zur-markenuebersicht') }}
      </button>
    </div>
    <div
      class="list_banner"
      v-if="getModelle.make_banner && getModelle.make_banner != ''"
    >
      <img :src="getModelle.make_banner" />
    </div>
    <div
      class="list_filter its-ak-flex sm:its-ak-flex-row its-ak-flex-col"
      v-if="getModelle.list_filters && getModelle.list_filters.length"
    >
      <div class="txtclr4 txtsize4">{{ $t('filter') }}:</div>
      <div
        class="filter_wrapper"
        v-for="(filter, fIx) in getModelle.list_filters"
        :key="fIx"
      >
        <div
          @click="doFilter(filter.bezeichnung)"
          :id="htmlId(filter.bezeichnung)"
          :data-filter="filter.bezeichnung"
          class="list_filter_button button_ak button_ak_light"
        >
          {{ filter.bezeichnung }}
        </div>
        <div class="filter_help_icon_wrapper" v-if="filter.text.length">
          <span class="filter_help_icon">?</span>
          <div class="filter_help" v-html="filter.text" />
        </div>
      </div>
    </div>
    <div class="wrapper_grid">
      <div
        class="
          its-ak-grid
          sm:its-ak-grid-cols-1
          md:its-ak-grid-cols-2
          lg:its-ak-grid-cols-3
          its-ak-mt-0
        "
      >
        <div
          v-for="(model, mIx) in getModelle.list_items"
          :key="mIx"
          @click="offers(model.id)"
          class="wrapper_model"
          :data-filter="model.so_filter"
        >
          <div class="wrapper_model_inner">
            <div class="wrapper_model_image">
              <img class="model_image" :src="model.so_image" />
            </div>
            <div class="make_model1" v-html="model.so_make_model1" />
            <hr />
            <div class="price" v-html="model.so_price" />
            <div class="text_panel" v-html="model.so_text_panel" />
            <div class="wrapper_button">
              <div class="button_ak">
                {{ $t('zum-angebot') }} <span class="chevron right"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!makeMode" class="wrapper_back_button">
      <button class="button_ak button_ak_light" @click="back">
        {{ $t('zurueck-zur-markenuebersicht') }}
      </button>
    </div>
  </div>

  <div v-else class="loading">...loading</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Modelle',
  computed: {
    ...mapGetters('filter', ['getModelle', 'getCurrentMarkeName']),
    ...mapGetters('env', ['makeMode']),
  },
  methods: {
    ...mapActions('filter', ['loadModelle', 'loadDetail']),
    ...mapActions('env', ['routeHome']),
    ...mapMutations('env', ['setCurrentComp']),
    ...mapMutations('filter', ['setCurrentModel']),
    async offers(id) {
      this.setCurrentModel(id)
      await this.loadDetail()
      this.setCurrentComp('Detail')
      document.querySelector('#wrapper-so-widget').scrollIntoView()
      this.$store.dispatch('formData/initAddBoxes')
      if (
        this.$store.state.env.isLocal &&
        !this.$store.state.formData.formData.surname
      ) {
        this.$store.commit('formData/initFormData')
        this.$store.commit('formData/setStep', 1)
      }
    },
    back() {
      this.routeHome()
    },
    htmlId(filter) {
      let id = filter.replaceAll(' ', '-')
      return 'btn-' + id
    },
    doFilter(filter) {
      //clicked Filterbutton
      let id = 'btn-' + filter.replaceAll(' ', '-')
      let btn = document.getElementById(id)
      //Button togglen
      if (btn.classList.contains('active')) {
        btn.classList.remove('active')
      } else {
        //Button war inactive, nicht passende Angebote ausblenden
        btn.classList.add('active')
      }

      //alle active Filterwerte
      const filters = document.getElementsByClassName('list_filter_button')
      const activeFilters = []
      filters.forEach((item) => {
        if (item.classList.contains('active')) {
          activeFilters.push(item.dataset.filter)
        }
      })
      //alle Angebote
      const offers = document.getElementsByClassName('wrapper_model')
      offers.forEach((item) => {
        let show = false
        //alle anzeigen wenn keine Filter gesetzt
        if (activeFilters.length == 0) {
          show = true
        }
        activeFilters.forEach((acFi) => {
          if (item.hasAttribute('data-filter')) {
            if (item.dataset.filter.includes(acFi)) {
              show = true
            }
          }
        })
        if (show) item.style.display = 'block'
        else item.style.display = 'none'
      })
    },
  },
}
</script>

<style lang="scss"></style>
