<template>
  <div>
    <div class="its-ak-mt-6">
      <section
        v-if="
          getDetail.so_category == 'Leasing' ||
          getDetail.so_category == 'Finanzierung'
        "
      >
        <div v-if="getDetail.so_leasing_automatic_response">
          <VSelect
            :vref="$v.formData.leasingcondition"
            :label="$t('leasingvariante')"
            :options="leasingConditionOptions"
          />

          <VSelect
            :vref="$v.formData.laufleistung"
            :label="$t('laufleistung-in-km')"
            :options="laufleistungOptions"
          />

          <VSelect
            :vref="$v.formData.laufzeit"
            :label="$t('laufzeit-in-monaten')"
            :options="laufzeitOptions"
          />

          <VText
            :vref="$v.formData.anzahlung"
            :label="$t('anzahlung')"
            readonly
          />
        </div>
        <div v-else>
          <VSelect
            :vref="$v.formData.laufleistung"
            :label="$t('laufleistung')"
            :options="laufleistungOptions"
          />

          <VSelect
            :vref="$v.formData.laufzeit"
            :label="$t('laufzeit')"
            :options="laufzeitOptions"
          />

          <VText :vref="$v.formData.anzahlung" :label="$t('anzahlung')" />
        </div>
      </section>

      <AddBoxes />
    </div>

    <FormButtons
      @next="submit()"
      @back="back()"
      :step="3"
      :submitDisabled="submitDisabled"
    />
  </div>
</template>

<script>
// import VText from '@vuelidate/VText.vue'
import AddBoxes from '@components/AddBoxes.vue'
import VSelect from '@vuelidate/VSelect.vue'
import VText from '@vuelidate/VText.vue'
import vuelidatePublicMixin from '@vuelidate/mixins/vuelidatePublicMixin.js'
import FormButtons from '@components/FormButtons.vue'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'DetailForm3',
  mixins: [vuelidatePublicMixin],
  components: {
    AddBoxes,
    VSelect,
    VText,
    FormButtons,
  },
  computed: {
    ...mapGetters('filter', ['getDetail']),
    laufzeitOptions() {
      let options
      if (this.$store.state.filter.detail.so_leasing_automatic_response) {
        options =
          this.$store.state.filter.detail.so_leasing_duration_options.map(
            (el) => ({ label: el, value: el })
          )
      } else {
        options = this.$store.state.filter.detail.so_request_options1
          .trim()
          .split('\n')
          .map((el) => ({ label: el, value: el }))
      }

      if (!this.$store.state.formData.formData.laufzeit) {
        this.$store.commit('formData/initLaufzeit', options[0].value)
      }
      return options
    },

    laufleistungOptions() {
      let options
      if (this.$store.state.filter.detail.so_leasing_automatic_response) {
        options = this.$store.state.filter.detail.so_leasing_km_options.map(
          (el) => ({ label: el, value: el })
        )
      } else {
        options = this.$store.state.filter.detail.so_request_options2
          .trim()
          .split('\n')
          .map((el) => ({ label: el, value: el }))
      }

      if (!this.$store.state.formData.formData.laufleistung) {
        this.$store.commit('formData/initLaufleistung', options[0].value)
      }

      return options
    },

    leasingConditionOptions() {
      let conditions =
        this.$store.state.filter.detail.so_leasing_condition_rate_options
      const optionsarr = Object.entries(conditions)
      let options
      options = optionsarr.map((el) => ({ label: el[1], value: el[0] }))
      if (!this.$store.state.formData.formData.leasingcondition) {
        this.$store.commit('formData/initLeasingCondition', options[0].value)
      }

      return options
    },

    formData: {
      get: function () {
        return this.$store.state.formData.formData
      },
      set: function (formData) {
        this.$store.commit('formData/setFormData', formData)
      },
    },
  },
  methods: {
    submit() {
      if (!this.validateAll()) {
        return
      }
      this.$store.commit('formData/setFormData', this.formData)
      this.$store.commit('formData/setStep', 4)
    },
    back() {
      this.$store.commit('formData/setFormData', this.formData)
      this.$store.commit('formData/setStep', 2)
    },
  },
  validations() {
    let validations = {
      formData: {
        finanzierung: {},
        barkauf: {},
        laufzeit: {},
        laufleistung: {},
        leasingcondition: {},
        anzahlung: { required },
      },
    }

    return validations
  },
  created() {
    if (this.$store.state.filter.detail.so_leasing_automatic_response) {
      this.$store.commit(
        'formData/initAnzahlung',
        this.$store.state.filter.detail.so_leasing_initial_payment
      )
    } else if (this.$store.state.filter.detail.so_request_initial) {
      this.$store.commit(
        'formData/initAnzahlung',
        this.$store.state.filter.detail.so_request_initial.trim()
      )
    }
    this.$store.commit('formData/initLeasingCondition', '')
  },
}
</script>
