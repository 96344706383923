import Vue from 'vue'
import Vuex from 'vuex'
import 'es6-promise/auto'

import env from './modules/env'
import filter from './modules/filter'
import formData from './modules/formData'

// Import the `getField` getter and the `updateField`
// mutation function from the `vuex-map-fields` module.
// import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    env,
    filter,
    formData,
  },
})
