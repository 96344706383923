import Vue from 'vue'
// import App from "./App.vue"
import Aktionsangebote from './Aktionsangebote.vue'
import store from './store/store.js'
import Vuelidate from 'vuelidate'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

// import axios from "axios"
import apiClient from './services/ApiClient'

import './assets/styles/tailwind.css'

Vue.config.productionTip = false

// import axios from "axios"

Vue.prototype.$axios = apiClient
// window.aoxios = axios

// axios.defaults.baseURL = store.state.apiHost

Vue.prototype.$getAgentToken = function () {
  return this.$root.$rootAttributes.getNamedItem('order-agent-token')?.value
}

Vue.prototype.$getMake = function () {
  // if (window.location.search.match(/Testmodell/)) {
  //     return (this.$root.$make = "Alfa Romeo")
  // }

  if (
    this.$root.$rootAttributes.getNamedItem('make') &&
    this.$root.$rootAttributes.getNamedItem('make').value != ''
  ) {
    return (this.$root.$make =
      this.$root.$rootAttributes.getNamedItem('make').value)
  }

  return false
}

Vue.use(Vuelidate)
Vue.use(CoolLightBox)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'de', // set locale
  messages, // set locale messages
})

Vue.prototype.$rootAttributes = {}

const loadLanguageAsync = async (lang) => {
  await import(
    /* webpackChunkName: "lang-[request]" */ './i18n/' + lang + '.json'
  ).then((response) => {
    messages[lang] = response.default
    i18n.locale = lang
    //window.vueRoot.$store.state.env.langLoaded = true
    store.state.env.langLoaded = true
  })
}

new Vue({
  store,
  i18n,
  render: (h) => h(Aktionsangebote),
  beforeMount: async function () {
    this.$root.$rootAttributes = this.$el.attributes

    if (
      this.$root.$rootAttributes.getNamedItem('market') &&
      this.$root.$rootAttributes.getNamedItem('market').value != ''
    ) {
      store.commit(
        'env/setMarket',
        this.$root.$rootAttributes.getNamedItem('market').value
      )
    }
    await loadLanguageAsync(store.getters['env/getLang'])
  },
}).$mount('#app')
