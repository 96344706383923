<template>
  <div
    v-if="getMarken && !makeDelay && $store.state.env.langLoaded"
    class="marken"
  >
    <div
      v-for="(marke, mIx) in getMarken"
      :key="mIx"
      @click="offers(marke.make_id, mIx)"
      class="wrapper_make"
    >
      <div class="make_inner its-ak-flex flex-flow">
        <!-- its-ak-grid its-ak-grid-cols-1 sm:its-ak-grid-cols-4 its-ak-gap-3 -->
        <div
          class="
            its-col-xs-12 its-col-sm-6 its-col-md-2 its-ak-mb-4
            lg:its-ak-mb-0
            its-ak-text-center
            lg:its-ak-text-left
          "
        >
          <!--its-ak-self-center its-ak-justify-self-center sm:its-ak-justify-self-start -->
          <img
            class="
              make_img
              its-ak-object-none its-ak-object-center its-ak-w-36 its-ak-h-36
            "
            :src="marke.make_img"
          />
        </div>

        <div
          class="
            its-col-xs-12
            its-col-sm-6
            its-col-md-5
            its-ak-self-center
            its-ak-justify-self-center
            its-ak-text-center
            its-ak-mb-4
            lg:its-ak-mb-0
          "
        >
          <span class="make" v-html="marke.make_text" />
          <span class="make_sub" v-html="marke.make_subtext" />
        </div>

        <div
          class="
            its-col-xs-12
            its-col-sm-6
            its-col-md-3
            its-ak-text-center
            its-ak-self-center
            its-ak-justify-self-center
            its-ak-mb-4
            lg:its-ak-mb-0
            wrapper_price
          "
        >
          <span class="txt1 its-ak-mr-2" v-html="marke.make_price_text1" />
          <span class="price" v-html="marke.make_price_text2" />
          <br />
          <span class="txt1" v-html="marke.make_price_text3" />
        </div>
        <div
          class="
            its-col-xs-12
            its-col-sm-6
            its-col-md-2
            its-ak-self-center
            its-ak-justify-self-center
            its-ak-text-center
            lg:its-ak-text-right
          "
        >
          <div class="button_ak">
            {{ $t('alle-angebote') }}<span class="chevron right"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="loading">...loading</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Marken',
  computed: {
    ...mapGetters('filter', ['getMarken']),
  },
  data() {
    return {
      makeDelay: true,
    }
  },
  methods: {
    ...mapActions('filter', ['loadMarken', 'loadModelle']),
    ...mapMutations('env', ['setCurrentComp']),
    ...mapMutations('filter', ['setCurrentMake']),
    async offers(id, ix) {
      this.setCurrentMake({ id, ix })
      await this.loadModelle()
      this.setCurrentComp('Modelle')
      document.querySelector('#wrapper-so-widget').scrollIntoView()
    },
  },
  mounted() {
    const context = this
    const token = this.$getAgentToken()
    this.$store.commit('env/setAgentToken', token)
    // console.log('token', token)

    this.loadMarken().then(function (marken) {
      const make = context.$getMake()
      if (make) {
        const markeIx = marken.findIndex((el) => el.make_text === make)
        if (markeIx > -1) {
          context.offers(marken[markeIx].make_id, markeIx)
          context.$store.commit('env/setMake', context.$getMake()) // Attribut make wurde verarbeitet
          context.$store.commit('env/setMakeMode', true)

          return
        }
      }
      context.makeDelay = false
    })
  },
}
</script>

<style lang="scss"></style>
