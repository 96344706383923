<template>
  <div class="v-checkbox">
    <div class="its-ak-flex">
      <input
        :id="_id"
        @input="
          (e) => {
            $emit('setModel', e.currentTarget.checked)
            $emit('input', e.currentTarget.checked)
            inputKeyup()
            inputBlur()
          }
        "
        :checked="vref.$model"
        type="checkbox"
        ref="vCheckbox"
        @blur="inputBlur"
        v-bind="$attrs"
        :tabindex="tabindex"
        :autofocus="autofocus"
        class="
          its-ak-cursor-pointer
          focus:its-ak-ring-indigo-500
          its-ak-h-4
          its-ak-w-4
          its-ak-text-indigo-600
          its-ak-border-gray-300
          its-ak-rounded
          its-ak-mr-3
        "
        :class="{ 'its-ak-border-red-300': getErrorMsgs() }"
      />
      <div class="label">
        <label :for="_id" v-html="label" />
        <p
          class="its-ak-text-gray-500"
          v-if="description"
          v-html="description"
        />
        <!-- <div
          v-if="!noErrorSpace && !vref.$anyError"
          class="its-ak-h-5 its-ak-mt-2 its-ak-text-red-500 its-ak-full-width"
          v-html="getErrorMsgs() + 'XX'"
        /> -->
      </div>
      <div
        v-show="getErrorMsgs()"
        :style="errorStyleObject"
        class="
          its-ak-absolute
          its-ak-inset-y-0
          its-ak-right-0
          its-ak-pr-3
          its-ak-flex
          its-ak-items-center
          its-ak-pointer-events-none
        "
      >
        <Icon name="validationError" />
      </div>
      <div
        class="its-ak-h-5 its-ak-text-red-500 its-ak-ml-2"
        v-html="getErrorMsgs()"
      />
    </div>
  </div>
</template>

<script>
import vuelidateMixin from '@vuelidate/mixins/vuelidateMixin.js'
import Icon from '@helper/Icon'

export default {
  name: 'VCheckbox',
  mixins: [vuelidateMixin],
  components: {
    Icon,
  },
  props: {
    description: String,
    noErrorSpace: Boolean,
  },
  computed: {
    errorStyleObject() {
      return this.roundedLabel && this.type === 'number'
        ? { right: '30px' }
        : {}
    },
  },
  methods: {
    clearValue() {
      this.$emit('setModel', false)
    },
  },
}
</script>

<style lang="scss"></style>
