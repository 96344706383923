import mkFormData from '@helper/formData.js'
import apiClient from '@/services/ApiClient'

export default {
  namespaced: true,
  state: {
    currentMake: null,
    currentMarkeIx: null,
    currentModel: null,
    marken: [],
    modelle: [],
    detail: null,
    make: null,
  },

  getters: {
    getMarken: (state) => state.marken,
    getCurrentMarkeName: (state) =>
      state.currentMarkeIx !== null
        ? state.marken[state.currentMarkeIx].make_text
        : '',
    getCurrentMake: (state) => state.currentMake,
    getCurrentModel: (state) => state.currentModel,
    getModelle: (state) => state.modelle,
    getDetail: (state) => state.detail,
    // getKunde: (state) =>
    //   state.kunde || {
    //     address_title: '',
    //     address_surname: '',
    //     address_name: '',
    //     address_firm: '',
    //     address_tel1: '',
    //     address_email: '',
    //     acceptTerms: false,
    //   },
  },
  mutations: {
    setMarken(state, marken) {
      state.marken = marken
    },
    setModelle(state, modelle) {
      state.modelle = modelle
    },
    setDetail(state, detail) {
      state.detail = detail
    },
    setCurrentMake(state, { id, ix }) {
      state.currentMake = id
      state.currentMarkeIx = ix
    },
    setCurrentModel(state, currentModel) {
      state.currentModel = currentModel
    },
  },
  actions: {
    async loadMarken(context) {
      let response
      const data = mkFormData({
        order_agent_token: context.rootState.env.orderAgentToken,
        mode: 'getMakes',
        market: context.rootState.env.market,
      })

      try {
        response = await apiClient.post('/', data)
        const marken = response.data
        context.commit('setMarken', marken)
      } catch (err) {
        console.log(err)
      }

      return response.data
    },
    async loadModelle(context) {
      const id = context.state.currentMake
      const data = mkFormData({
        order_agent_token: context.rootState.env.orderAgentToken,
        mode: 'getList',
        make_id: id,
        market: context.rootState.env.market,
      })

      try {
        const response = await apiClient.post('/', data)
        context.commit('setModelle', response.data)
      } catch (err) {
        console.log(err)
      }
    },
    async loadDetail(context) {
      const id = context.state.currentModel

      const data = mkFormData({
        order_agent_token: context.rootState.env.orderAgentToken,
        mode: 'getOffer',
        offer_id: id,
        market: context.rootState.env.market,
      })

      try {
        const response = await apiClient.post('/', data)
        context.commit('setDetail', response.data)
      } catch (err) {
        console.log(err)
      }
    },
  },
}
