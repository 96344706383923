export default {
  props: {
    id: String, // optional, will bei calculated if not here
    label: String,
    tabindex: {
      type: Number,
      default: 0,
    },
    vref: Object, // Referenz to the Validation-Object
    autofocus: Boolean,
    autoselect: Boolean,
    readonly: Boolean,
  },
  computed: {
    _id() {
      return this.uid
    },
    $parentV() {
      // looks rekursie for $v-Root
      const get$V = (node) => {
        if (node.$v) return node.$v
        if (node === node.$root) return null
        return get$V(node.$parent)
      }
      const $pv = get$V(this)
      return $pv
    },
  },
  data() {
    return {
      uid: null,
      blurred: false, // indicates, if Field has been blurred at least
    }
  },
  methods: {
    inputBlur() {
      // console.log('inputBlur')
      this.blurred = true
      // this.$nextTick(() => {
      this.vref.$touch()
      this.syncInnerError()
      // })
    },
    inputKeyup() {
      // console.log('inputKeyup')
      // this.$nextTick(() => {
      this.vref.$touch()
      this.syncInnerError()
      // })
    },
    syncInnerError() {
      // Quasar-Element: falls vorhanden Error-Status setzen
      typeof this.$refs.vInput === 'object' &&
        (this.$refs.vInput.innerError =
          this.blurred || this.$parentV.$showAllErrors) &&
        this.vref.$invalid
    },
    getErrorMsgs() {
      // console.log("called: getErrorMsgs");
      const node = this.vref
      if (
        !node ||
        !node.$anyError ||
        (!this.$parentV.$showAllErrors && !this.blurred) ||
        !node.$invalid
      ) {
        return ''
      }

      const loadCustomMsgs = (node) => {
        // FIXME: ???
        if (node.errorMessages) return node.errorMessages
        if (node === node.$root) return null
        return loadCustomMsgs(node.$parent)
      }

      var allMsgs = [
        {
          rule: 'minLength',
          msg:
            node.$params.minLength && node.$params.minLength.min
              ? this.$t('mindestens') +
                ` ${node.$params.minLength.min} ` +
                this.$t('zeichen') +
                `!`
              : '',
        },
        {
          rule: 'maxLength',
          msg:
            node.$params.maxLength && node.$params.maxLength.max
              ? this.$t('hoechstens') +
                ` ${node.$params.maxLength.max} ` +
                this.$t('zeichen') +
                `!`
              : '',
        },
        {
          rule: 'minValue',
          msg:
            node.$params.minValue && node.$params.minValue.min
              ? `Mindestwert ${node.$params.minValue.min}!`
              : '',
        },
        {
          rule: 'min',
          msg:
            node.$params.minValue && node.$params.minValue.min
              ? this.$t('mindestens') + ` ${node.$params.min.min}!`
              : '',
        },
        {
          rule: 'maxValue',
          msg:
            node.$params.maxValue && node.$params.maxValue.max
              ? `Höchstwert ${node.$params.maxValue.max}!`
              : '',
        },
        { rule: 'email', msg: this.$t('email-ungueltig') },
        { rule: 'validEmail', msg: this.$t('email-ungueltig') },
        { rule: 'required', msg: this.$t('pflichtfeld') },
        { rule: 'validPLZ', msg: this.$t('plz-ungueltig') },
        { rule: 'validOrEmptyPLZ', msg: this.$t('plz-gueltig-leer') },
        { rule: 'validDate', msg: this.$t('datum-gueltig') },
        { rule: 'numeric', msg: this.$t('nur-numerische-werte') },
        { rule: 'sameAs', msg: this.$t('eingaben-uebereinstimmen') },
        { rule: 'checked', msg: this.$t('bitte-bestaetigen') },
        { rule: 'selectOption', msg: this.$t('option-waehlen') },
        {
          rule: 'between',
          msg: node.$params.between
            ? `Nur Werte zwischen ${node.$params.between.min} und ${node.$params.between.max}!`
            : '',
        },
        {
          rule: 'complexPass',
          msg: 'Das Passwort muss Groß- und Kleinbuchstaben sowie mindestens eine Ziffer enthalten!',
        },
      ]

      const customMsgs = loadCustomMsgs(this)
      customMsgs && (allMsgs = customMsgs.concat(allMsgs))

      var msgs = []

      for (var type in node.$params) {
        if (!node[type]) {
          const el = allMsgs.find((el) => el.rule == type)
          el && msgs.push(el.msg)
        }
      }

      if (Object.keys(node.$params).length && !msgs.length) {
        // vermutlich ist eine Message nicht definiert
        msgs.push('Der eingetragene Wert ist ungültig!')
      }

      return msgs.join(' ')
    },
  },
  created() {
    this.$on('setModel', (value) => {
      this.vref.$model = value
      // console.log('setModel', value)
      // this.vref.$touch()
    })
  },
  mounted() {
    this.uid = this.id ? this.id : `uid_${this._uid}`
  },
  beforeDestroy() {
    this.$off()
  },
}
