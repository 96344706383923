/**
 * Mixin für mit Vuelidate validierte Forms.
 *
 * Verhalten:
 * Einzelne Felder werden erst nach blur() zur Validierung freigegeben.
 * Der User würde sonst Fehlermeldungen sehen, bevor er die Möglichkeit hatte
 * einen validen Wert einzugeben, etwa nach dem ersten Zeichen in einem PLZ-Feld.
 *
 * Bereits mindestens einmal verlassene Felder werden dagegen onKeyUp validiert.
 *
 * submitDisabled steuert die Validierung des gesamten Forms und den Status des Submit-Buttons.
 *
 * Auch hier bleibt der Button solange enabled, bis er das erste Mal geklickt wird.
 * Dabei wird das gesamte Form einschließlich aller Felder, die noch nicht aktiv waren validiert.
 *
 * Danach wird auch submitDisabled bei jedem Tastendruck aktiviert.
 *
 * Hintergrund:
 *
 * Es ist nicht möglich, den Status des Submit-Buttons sicher festzustellen,
 * bevor nicht das gesamte Form validiert ist. Es wäre zwar möglich, den Button auf
 * disabled zu stelle, sobald onBlur mindestens ein Fehler festgestellt würde.
 * Das bedeutet aber nicht, dass nicht noch andere Felder invalid sind, die
 * noch nie aktiv waren und. Um auch auf Form-Ebene nicht unnötig viele Fehler
 * anzuzeigen werden diese Felder aber erst beim ersten Submit-Versuch validiert.
 *
 * Der Button könnte so also bei Korrektur eines Feldes auf enabled springen um dann
 * nach Click doch wieder disabled zu sein.
 *
 * Vor diesem Hintergrund erscheint es konsistenter, den Status des Buttons auch
 * erst dann zu ändern, wenn das gesamte Form mit allen Feldern validiert ist,
 * also nach dem ersten Click auf den Button.
 *
 * Dazu muss der Click-Handler des Buttons zunächst folgenden Code ausführen:
 *
 *       if (!this.validateAll()) {
 *         return
 *       }
 *
 * um im Hintergrund die Form-Validierung einzuschalten und submitDisabled zu berechnen.
 *
 * Achtung!
 * Die Validierung funktioniert nur, wenn in die zu validierenden Objekte leer
 * bzw. mit Defaultwerten angelegt sind. Andernfalls kann Vuelidate die nötigen Aktionen
 * nicht sauber an die Änderungen des Model koppeln.
 *
 * Muster:
 *
 * data() {
 *  return {
 *    form: {
 *      first_name: "",
 *      last_name: "",
 *      email: "",
 *      ...
 *    }
 *  }
 * }
 *
 * Ein Eintrag der Regeln in vaidate: {...} reicht nicht aus.
 */
export default {
  computed: {
    submitDisabled() {
      // steuert den disabled-State des Submit-Buttons
      return this.$v.$invalid && this.checkSubmitState
    },
  },
  data() {
    return {
      checkSubmitState: false, // Steuert, ob der disabled-State des Submit-Buttons vom Ergebnis der Validierung abhängig sein muss. Initial ist das nicht der Fall
    }
  },
  methods: {
    resetValidation() {
      // etwa um in beforeRouteEnter() die Validierung zurückzusetzen
      const resetChild = (node) => {
        for (var att in node) {
          att == '$blurred' && (node[att] = false)
          !att.match(/^\$/) &&
            typeof node[att] == 'object' &&
            resetChild(node[att])
        }
      }

      resetChild(this.$v)

      this.$v.$reset()
      this.$v.$showAllErrors = false
    },
    validateAll() {
      // Prüft insbesondere beim ersten Submit, ob das Model valid ist oder ob der Submitt-Button deaktiviert werden muss
      if (this.$v.$invalid) {
        this.checkSubmitState = true
        this.$v.$showAllErrors = true
        this.$v.$touch() // Meldungen anzeigen!
        return false
      }

      return true
    },
  },
}
